var exports = {};

function valueOf(e) {
  return e.valueOf ? e.valueOf() : Object.prototype.valueOf.call(e);
}

function valueEqual(u, r) {
  if (u === r) return !0;
  if (null == u || null == r) return !1;
  if (Array.isArray(u)) return Array.isArray(r) && u.length === r.length && u.every(function (e, u) {
    return valueEqual(e, r[u]);
  });
  if ("object" != typeof u && "object" != typeof r) return !1;
  var e = valueOf(u),
      t = valueOf(r);
  return e !== u || t !== r ? valueEqual(e, t) : Object.keys(Object.assign({}, u, r)).every(function (e) {
    return valueEqual(u[e], r[e]);
  });
}

exports = valueEqual;
export default exports;